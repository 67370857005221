<template>
  <div class="container-fluid main-container">
    <div class="row mt-1">
      <div class="col-3 main-col overflow-auto">
        <select class="form-select mb-2" v-model="currentSelectedSetIndex">
          <option value="-1">选择套装</option>
          <option v-for="(set, index) in itemSetList" :key="set.id" :value="index">
            {{ set.game_ver }} - {{ set.name }} {{ set.type == 1 ? '战斗套' : '生产采集套' }}
          </option>
        </select>

        <div class="card" v-if="currentSelectedSetIndex == -1 || isLoadingItemSet">
          <div class="card-body text-center">
            <span class="text-muted" v-if="currentSelectedSetIndex == -1 && !isLoadingItemSet">请选择套装</span>
            <span class="text-muted" v-if="isLoadingItemSet">
              <span class="spinner-border spinner-border-sm text-primary"></span>
              正在加载中...
            </span>
          </div>
        </div>

        <template v-else>
          <ItemSetGearSelection :type="currentSelectedSetIndex != -1 ? itemSetList[currentSelectedSetIndex].type : 0"
            :gearCount="gearCount" @add="gearAdd" @remove="gearRemove">
          </ItemSetGearSelection>

          <ItemSetWeaponSelection :type="currentSelectedSetIndex != -1 ? itemSetList[currentSelectedSetIndex].type : 0"
            :weaponCount="weaponCount" @add="weaponAdd" @remove="weaponRemove" @clear="weaponRemove(-1)">
          </ItemSetWeaponSelection>
        </template>
      </div>

      <div class="col main-col">
        <div class="row pb-3 ingredient-row-height">
          <div class="col-4 card-col">
            <div class="card card-content">
              <div class="card-header">
                普通半成品
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="(amount, index) in commonIntermediate" :key="index">
                  <a :href="'#/item/' + index">
                    <img :src="this.$root.getIconURL(itemSetInfo.item_data[index].icon)"
                      class="rounded ingredient-icon" />
                  </a>
                  <span class="ms-1">{{ itemSetInfo.item_data[index].name }} x{{ amount }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-4 card-col">
            <div class="card card-content">
              <div class="card-header">
                秘籍半成品
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="(amount, index) in bookIntermediate" :key="index">
                  <a :href="'#/item/' + index">
                    <img :src="this.$root.getIconURL(itemSetInfo.item_data[index].icon)"
                      class="rounded ingredient-icon" />
                  </a>
                  <span class="ms-1">{{ itemSetInfo.item_data[index].name }} x{{ amount }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-4 card-col">
            <div class="card card-content">
              <div class="card-header">
                炼金半成品
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="(amount, index) in alcIntermediate" :key="index">
                  <a :href="'#/item/' + index">
                    <img :src="this.$root.getIconURL(itemSetInfo.item_data[index].icon)"
                      class="rounded ingredient-icon" />
                  </a>
                  <span class="ms-1">{{ itemSetInfo.item_data[index].name }} x{{ amount }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row ingredient-row-height">
          <div class="col-4 card-col">
            <div class="card card-content">
              <div class="card-header">
                点数材料/票据材料
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="(amount, index) in stoneIngredient" :key="index">
                  <div>
                    <a :href="'#/item/' + index">
                      <img :src="this.$root.getIconURL(itemSetInfo.item_data[index].icon)"
                        class="rounded ingredient-icon" />
                    </a>
                    <span class="ms-1">{{ itemSetInfo.item_data[index].name }} x{{ amount }}</span>
                  </div>
                  <div>
                    <span class="badge bg-secondary me-1">兑换</span>
                    <a :href="'#/item/' + itemSetInfo.item_data[index].cost.item.id">
                      <img :src="this.$root.getIconURL(itemSetInfo.item_data[index].cost.item.icon)"
                        class="rounded ingredient-icon" :title="itemSetInfo.item_data[index].cost.item.name" />
                    </a>
                    <span class="ms-1 align-middle">x{{ itemSetInfo.item_data[index].cost.count }}</span>
                  </div>
                </li>

                <li class="list-group-item" v-for="(amount, index) in scripIngredient" :key="index">
                  <div>
                    <a :href="'#/item/' + index">
                      <img :src="this.$root.getIconURL(itemSetInfo.item_data[index].icon)"
                        class="rounded ingredient-icon" />
                    </a>
                    <span class="ms-1">{{ itemSetInfo.item_data[index].name }} x{{ amount }}</span>
                  </div>
                  <div>
                    <span class="badge bg-secondary me-1">兑换</span>
                    <a :href="'#/item/' + itemSetInfo.item_data[index].cost.item.id">
                      <img :src="this.$root.getIconURL(itemSetInfo.item_data[index].cost.item.icon)"
                        class="rounded ingredient-icon" :title="itemSetInfo.item_data[index].cost.item.name" />
                    </a>
                    <span class="ms-1 align-middle">x{{ itemSetInfo.item_data[index].cost.count }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-4 card-col">
            <div class="card card-content">
              <div class="card-header">
                限时材料
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="(amount, index) in limitTimeIngredient" :key="index">
                  <div>
                    <a :href="'#/item/' + index">
                      <img :src="this.$root.getIconURL(itemSetInfo.item_data[index].icon)"
                        class="rounded ingredient-icon" />
                    </a>
                    <span class="ms-1">{{ itemSetInfo.item_data[index].name }} x{{ amount }}</span>
                  </div>
                  <div>
                    <span class="badge bg-secondary">采集</span>
                    <span class="ms-1 align-middle">{{ itemSetInfo.item_data[index].location.map }}</span>
                    <span class="ms-1 badge bg-secondary">ET</span>
                    <span class="ms-1 align-middle">
                      <template v-for="(time, tindex) in itemSetInfo.item_data[index].time" :key="tindex">
                        {{ parseInt(time.start) > 100 ? parseInt(time.start) / 100 : time.start }}{{ tindex + 1 !=
                            itemSetInfo.item_data[index].time.length ? ', ' : ''
                        }}
                      </template>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-4 card-col">
            <div class="card card-content">
              <div class="card-header">
                灵砂
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="(amount, index) in   aethersand" :key="index">
                  <a :href="'#/item/' + index">
                    <img :src="this.$root.getIconURL(itemSetInfo.item_data[index].icon)"
                      class="rounded ingredient-icon" />
                  </a>
                  <span class="ms-1">{{ itemSetInfo.item_data[index].name }} x{{ amount }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="col-2-5 main-col">
        <div class="card card-content">
          <div class="card-header">
            制作成品列表
          </div>
          <div class="card-body pt-2 pb-2">
            <select class="form-select" v-model="currentSelectedRecipeJob">
              <option value="-1">全部职业 ({{ Object.keys(allItems).length }})</option>
              <option value="0">刻木 ({{ jobItemCount[0] }})</option>
              <option value="1">锻铁 ({{ jobItemCount[1] }})</option>
              <option value="2">铸甲 ({{ jobItemCount[2] }})</option>
              <option value="3">雕金 ({{ jobItemCount[3] }})</option>
              <option value="4">制革 ({{ jobItemCount[4] }})</option>
              <option value="5">裁衣 ({{ jobItemCount[5] }})</option>
            </select>
          </div>
          <ul class="list-group list-group-flush" style="height: 100%">
            <li class="list-group-item" v-for="(amount, index) in allItems" :key="index"
              v-show="currentSelectedRecipeJob == -1 || currentSelectedRecipeJob == itemSetInfo.item_data[index].craftType">
              <div>
                <a :href="'#/item/' + index">
                  <img :src="this.$root.getIconURL(itemSetInfo.item_data[index].icon)"
                    class="rounded ingredient-icon" />
                </a>
                <span class="badge bg-secondary ms-1">
                  {{ getCraftTypeJob(itemSetInfo.item_data[index].craftType) }}
                </span>
                <span class="badge bg-secondary ms-1">
                  {{ itemSetInfo.item_data[index].itemUICategory }}
                </span>
              </div>
              <div class="ms-1" style="padding-left: 24px">
                {{ itemSetInfo.item_data[index].name }} x{{ amount }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemSetGearSelection from '../components/itemset/ItemSetGearSelection.vue';
import ItemSetWeaponSelection from '../components/itemset/ItemSetWeaponSelection.vue';

export default {
  name: "ItemSet",
  components: {
    ItemSetGearSelection,
    ItemSetWeaponSelection
  },
  data() {
    return {
      itemSetList: {},
      currentSelectedSetIndex: -1,
      currentSelectedRecipeJob: -1,
      jobItemCount: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      },

      isLoadingItemSet: false,
      itemSetInfo: null,

      weaponCount: {},
      gearCount: {},

      allItems: {},

      commonIntermediate: {},
      bookIntermediate: {},
      alcIntermediate: {},

      stoneIngredient: {},
      scripIngredient: {},
      limitTimeIngredient: {},
      aethersand: {},
    };
  },
  created() {
    var self = this;
    this.axios
      .get(this.apiBaseURL + "item_set.php?method=getItemSetList")
      .then(function (response) {
        if (response.data.success) {
          self.itemSetList = response.data.data;
        } else {
          alert("发生错误：" + response.data.error + "，请稍后重试。");
        }
      })
      .catch(function (error) {
        alert("服务器错误，请稍后重试。(" + error + ")");
      });
  },
  watch: {
    currentSelectedSetIndex() {
      if (this.currentSelectedSetIndex != -1) {
        this.getItemSetInfo();
      }

      this.weaponCount = {};
      this.gearCount = {};

      this.allItems = {};

      this.commonIntermediate = {};
      this.bookIntermediate = {};
      this.alcIntermediate = {};

      this.stoneIngredient = {};
      this.scripIngredient = {};
      this.limitTimeIngredient = {};
      this.aethersand = {};
    },
    gearCount: {
      handler() {
        Object.keys(this.gearCount).forEach(key => {
          Object.keys(this.gearCount[key]).forEach(slotKey => {
            var itemID = this.itemSetInfo.gears[key][slotKey];
            var amount = this.gearCount[key][slotKey];

            if (amount == 0) {
              delete this.allItems[itemID];
            } else {
              this.allItems[itemID] = amount;
            }
          });
        });
      },
      deep: true,
    },
    weaponCount: {
      handler() {
        Object.keys(this.weaponCount).forEach(key => {
          var itemIDs = this.itemSetInfo.weapons[key];
          var amount = this.weaponCount[key];

          itemIDs.forEach(element => {
            if (amount == 0) {
              delete this.allItems[element];
            } else {
              this.allItems[element] = amount;
            }
          });
        });
      },
      deep: true,
    },
    allItems: {
      handler() {
        this.jobItemCount = {
          0: 0,
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
        };

        this.commonIntermediate = {};
        this.bookIntermediate = {};
        this.alcIntermediate = {};

        this.stoneIngredient = {};
        this.scripIngredient = {};
        this.limitTimeIngredient = {};
        this.aethersand = {};

        Object.keys(this.allItems).forEach(key => {
          var itemID = key;
          var amount = this.allItems[key];

          this.jobItemCount[this.itemSetInfo.item_data[key].craftType]++;

          this.interAmountUpdate(itemID, amount);
        });

        Object.keys(this.bookIntermediate).forEach(key => {
          var itemID = key;
          var amount = this.bookIntermediate[key];

          this.ingAmountUpdate(itemID, amount);
        });

        Object.keys(this.alcIntermediate).forEach(key => {
          var itemID = key;
          var amount = this.alcIntermediate[key];

          this.ingAmountUpdate(itemID, amount);
        });
      },
      deep: true,
    },
  },
  methods: {
    getCraftTypeJob(type) {
      type = parseInt(type);

      switch (type) {
        case 0:
          return "刻木匠";
        case 1:
          return "锻铁匠";
        case 2:
          return "铸甲匠";
        case 3:
          return "雕金匠";
        case 4:
          return "制革匠";
        case 5:
          return "裁衣匠";
        case 6:
          return "炼金术士";
        case 7:
          return "烹调师";
      }
    },
    getItemSetInfo() {
      this.isLoadingItemSet = true;

      var self = this;
      this.axios
        .post(this.apiBaseURL + "item_set.php?method=getItemSet", {
          id: this.itemSetList[this.currentSelectedSetIndex].id,
        })
        .then(function (response) {
          if (response.data.success) {
            self.itemSetInfo = response.data.data;
          } else {
            alert("发生错误：" + response.data.error + "，请稍后重试。");
            self.currentSelectedSetIndex = -1;
          }

          self.isLoadingItemSet = false;
        })
        .catch(function (error) {
          alert("服务器错误，请稍后重试。(" + error + ")");

          self.currentSelectedSetIndex = -1;
          self.isLoadingItemSet = false;
        });
    },
    weaponAdd(jobID) {
      if (jobID == 101) {
        for (let i = 8; i <= 15; i++) {
          if (i in this.weaponCount) {
            this.weaponCount[i]++;
          } else {
            this.weaponCount[i] = 1;
          }
        }
      } else if (jobID == 102) {
        for (let i = 16; i <= 18; i++) {
          if (i in this.weaponCount) {
            this.weaponCount[i]++;
          } else {
            this.weaponCount[i] = 1;
          }
        }
      } else {
        if (jobID in this.weaponCount) {
          this.weaponCount[jobID]++;
        } else {
          this.weaponCount[jobID] = 1;
        }
      }
    },
    weaponRemove(jobID) {
      if (jobID == -1) {
        Object.keys(this.weaponCount).forEach((key) => {
          this.weaponCount[key] = 0;
        });
      } else {
        if (jobID in this.weaponCount) {
          if (this.weaponCount[jobID] <= 1) {
            this.weaponCount[jobID] = 0;
          } else {
            this.weaponCount[jobID]--;
          }
        }
      }
    },
    gearAdd(type, jobType) {
      if (jobType in this.gearCount == false) {
        this.gearCount[jobType] = {
          head: 0,
          body: 0,
          hand: 0,
          leg: 0,
          feet: 0,
          earring: 0,
          necklace: 0,
          bracelet: 0,
          ring: 0,
        };
      }

      switch (type) {
        case 'full':
          Object.keys(this.gearCount[jobType]).forEach(key => {
            if (key == 'ring') {
              this.gearCount[jobType][key] += 2;
            } else {
              this.gearCount[jobType][key]++;
            }
          });
          break;
        case 'gear':
          this.gearCount[jobType].head++;
          this.gearCount[jobType].body++;
          this.gearCount[jobType].hand++;
          this.gearCount[jobType].leg++;
          this.gearCount[jobType].feet++;
          break;
        case 'accessories':
          this.gearCount[jobType].earring++;
          this.gearCount[jobType].necklace++;
          this.gearCount[jobType].bracelet++;
          this.gearCount[jobType].ring += 2;
          break;
        default:
          this.gearCount[jobType][type]++;
          break;
      }
    },
    gearRemove(type, jobType) {
      switch (type) {
        case 'full':
          if (jobType in this.gearCount) {
            Object.keys(this.gearCount[jobType]).forEach(key => {
              if (key == 'ring') {
                this.gearCount[jobType][key] -= 2;
              } else {
                this.gearCount[jobType][key]--;
              }

              if (this.gearCount[jobType][key] < 0) {
                this.gearCount[jobType][key] = 0;
              }
            });
          }
          break;
        case 'clearCurrent':
          this.gearCount[jobType] = {
            head: 0,
            body: 0,
            hand: 0,
            leg: 0,
            feet: 0,
            earring: 0,
            necklace: 0,
            bracelet: 0,
            ring: 0,
          };
          break;
        case 'clearAll':
          Object.keys(this.gearCount).forEach((key) => {
            this.gearCount[key] = {
              head: 0,
              body: 0,
              hand: 0,
              leg: 0,
              feet: 0,
              earring: 0,
              necklace: 0,
              bracelet: 0,
              ring: 0,
            };
          });
          break;
        default:
          this.gearCount[jobType][type]--;

          if (this.gearCount[jobType][type] < 0) {
            this.gearCount[jobType][type] = 0;
          }
          break;
      }
    },
    interAmountUpdate(itemID, amount) {
      Object.keys(this.itemSetInfo.item_data[itemID].ingredients).forEach((ingKey) => {
        var ing = this.itemSetInfo.item_data[itemID].ingredients[ingKey];

        if (this.itemSetInfo.common_intermediate.includes(ing.id)) {
          if (this.commonIntermediate[ing.id] != null) {
            this.commonIntermediate[ing.id] += parseInt(ing.amount) * parseInt(amount);
          } else {
            this.commonIntermediate[ing.id] = parseInt(ing.amount) * parseInt(amount);
          }

          if (this.commonIntermediate[ing.id] <= 0) {
            delete this.commonIntermediate[ing.id];
          }
        } else if (this.itemSetInfo.book_intermediate.includes(ing.id)) {
          if (this.bookIntermediate[ing.id] != null) {
            this.bookIntermediate[ing.id] += parseInt(ing.amount) * parseInt(amount);
          } else {
            this.bookIntermediate[ing.id] = parseInt(ing.amount) * parseInt(amount);
          }

          if (this.bookIntermediate[ing.id] <= 0) {
            delete this.bookIntermediate[ing.id];
          }
        } else if (this.itemSetInfo.alc_intermediate.includes(ing.id)) {
          if (this.alcIntermediate[ing.id] != null) {
            this.alcIntermediate[ing.id] += parseInt(ing.amount) * parseInt(amount);
          } else {
            this.alcIntermediate[ing.id] = parseInt(ing.amount) * parseInt(amount);
          }

          if (this.alcIntermediate[ing.id] <= 0) {
            delete this.alcIntermediate[ing.id];
          }
        } else if (this.itemSetInfo.stone_ingredient.includes(ing.id)) {
          if (this.stoneIngredient[ing.id] != null) {
            this.stoneIngredient[ing.id] += parseInt(ing.amount) * parseInt(amount);
          } else {
            this.stoneIngredient[ing.id] = parseInt(ing.amount) * parseInt(amount);
          }

          if (this.stoneIngredient[ing.id] <= 0) {
            delete this.stoneIngredient[ing.id];
          }
        } else if (this.itemSetInfo.scrip_ingredient.includes(ing.id)) {
          if (this.scripIngredient[ing.id] != null) {
            this.scripIngredient[ing.id] += parseInt(ing.amount) * parseInt(amount);
          } else {
            this.scripIngredient[ing.id] = parseInt(ing.amount) * parseInt(amount);
          }

          if (this.scripIngredient[ing.id] <= 0) {
            delete this.scripIngredient[ing.id];
          }
        } else if (this.itemSetInfo.gather_limit_time_ingredient.includes(ing.id)) {
          if (this.limitTimeIngredient[ing.id] != null) {
            this.limitTimeIngredient[ing.id] += parseInt(ing.amount) * parseInt(amount);
          } else {
            this.limitTimeIngredient[ing.id] = parseInt(ing.amount) * parseInt(amount);
          }

          if (this.limitTimeIngredient[ing.id] <= 0) {
            delete this.limitTimeIngredient[ing.id];
          }
        } else if (this.itemSetInfo.aethersand.includes(ing.id)) {
          if (this.aethersand[ing.id] != null) {
            this.aethersand[ing.id] += parseInt(ing.amount) * parseInt(amount);
          } else {
            this.aethersand[ing.id] = parseInt(ing.amount) * parseInt(amount);
          }

          if (this.aethersand[ing.id] <= 0) {
            delete this.aethersand[ing.id];
          }
        } else {
          return;
        }
      });
    },
    ingAmountUpdate(interID, amount) {
      Object.keys(this.itemSetInfo.item_data[interID].ingredients).forEach((ingKey) => {
        var ing = this.itemSetInfo.item_data[interID].ingredients[ingKey];

        if (this.itemSetInfo.stone_ingredient.includes(ing.id)) {
          if (this.stoneIngredient[ing.id] != null) {
            this.stoneIngredient[ing.id] += parseInt(ing.amount) * parseInt(amount);
          } else {
            this.stoneIngredient[ing.id] = parseInt(ing.amount) * parseInt(amount);
          }

          if (this.stoneIngredient[ing.id] == 0) {
            delete this.stoneIngredient[ing.id];
          }
        } else if (this.itemSetInfo.scrip_ingredient.includes(ing.id)) {
          if (this.scripIngredient[ing.id] != null) {
            this.scripIngredient[ing.id] += parseInt(ing.amount) * parseInt(amount);
          } else {
            this.scripIngredient[ing.id] = parseInt(ing.amount) * parseInt(amount);
          }

          if (this.scripIngredient[ing.id] == 0) {
            delete this.scripIngredient[ing.id];
          }
        } else if (this.itemSetInfo.gather_limit_time_ingredient.includes(ing.id)) {
          if (this.limitTimeIngredient[ing.id] != null) {
            this.limitTimeIngredient[ing.id] += parseInt(ing.amount) * parseInt(amount);
          } else {
            this.limitTimeIngredient[ing.id] = parseInt(ing.amount) * parseInt(amount);
          }

          if (this.limitTimeIngredient[ing.id] == 0) {
            delete this.limitTimeIngredient[ing.id];
          }
        } else if (this.itemSetInfo.aethersand.includes(ing.id)) {
          if (this.aethersand[ing.id] != null) {
            this.aethersand[ing.id] += parseInt(ing.amount) * parseInt(amount);
          } else {
            this.aethersand[ing.id] = parseInt(ing.amount) * parseInt(amount);
          }

          if (this.aethersand[ing.id] == 0) {
            delete this.aethersand[ing.id];
          }
        } else {
          return;
        }
      });
    }
  },
}
</script>

<style scoped>
.col-2-5 {
  flex: 0 0 auto;
  width: 20%;
}

.card-content {
  height: 100%;
  overflow: hidden;
}

.card-content ul {
  overflow-y: auto;
}

.card-col {
  max-height: 100%;
}

.ingredient-row-height {
  height: 50%;
}

.main-col {
  height: calc(100vh - 140px);
  min-height: 550px;
  max-height: calc(100vh - 140px);
}

.ingredient-icon {
  width: 24px;
  height: 24px;
}
</style>
