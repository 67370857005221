<template>
  <span class="position-relative checked-job translate-middle badge rounded-pill bg-success">
    <i class="bi bi-check-lg"></i>
  </span>
</template>

<style scoped>
.checked-job {
  cursor: default;
  float: right;
  left: 80%;
  top: -10%;
}
</style>