<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 mb-2" v-if="type == 2">
          <div class="btn-group btn-group-sm w-100">
            <input type="radio" class="btn-check" name="jobtype" id="jobtype10" value="10" autocomplete="off"
              v-model="currentSelectedJobType">
            <label class="btn btn-outline-primary" for="jobtype10">生产</label>

            <input type="radio" class="btn-check" name="jobtype" id="jobtype11" value="11" autocomplete="off"
              v-model="currentSelectedJobType">
            <label class="btn btn-outline-primary" for="jobtype11">采集</label>
          </div>
        </div>

        <template v-else>
          <div class="col-12 mb-2">
            <div class="btn-group btn-group-sm w-100">
              <input type="radio" class="btn-check" name="jobtype" id="jobtype1" value="1" autocomplete="off"
                v-model="currentSelectedJobType">
              <label class="btn btn-outline-primary" for="jobtype1">坦克</label>

              <input type="radio" class="btn-check" name="jobtype" id="jobtype2" value="2" autocomplete="off"
                v-model="currentSelectedJobType">
              <label class="btn btn-outline-success" for="jobtype2">治疗</label>

              <input type="radio" class="btn-check" name="jobtype" id="jobtype3" value="3" autocomplete="off"
                v-model="currentSelectedJobType">
              <label class="btn btn-outline-danger" for="jobtype3">龙骑/钐镰</label>
            </div>
          </div>

          <div class="col-12">
            <div class="btn-group btn-group-sm w-100">
              <input type="radio" class="btn-check" name="jobtype" id="jobtype4" value="4" autocomplete="off"
                v-model="currentSelectedJobType">
              <label class="btn btn-outline-danger" for="jobtype4">武士/武僧</label>

              <input type="radio" class="btn-check" name="jobtype" id="jobtype5" value="5" autocomplete="off"
                v-model="currentSelectedJobType">
              <label class="btn btn-outline-danger" for="jobtype5">忍者</label>

              <input type="radio" class="btn-check" name="jobtype" id="jobtype6" value="6" autocomplete="off"
                v-model="currentSelectedJobType">
              <label class="btn btn-outline-danger" for="jobtype6">远敏</label>

              <input type="radio" class="btn-check" name="jobtype" id="jobtype7" value="7" autocomplete="off"
                v-model="currentSelectedJobType">
              <label class="btn btn-outline-danger" for="jobtype7">法系</label>
            </div>
          </div>
        </template>
      </div>

      <template v-if="type != 0">
        <hr class="mt-2 mb-2" />

        <div class="row">
          <div class="col-4 mb-2 pe-0">
            <button type="button" class="btn btn-primary btn-sm d-block"
              @click="$emit('add', 'full', currentSelectedJobType)">+ 当前整套</button>
          </div>
          <div class="col-4 mb-2 ps-2 pe-1">
            <button type="button" class="btn btn-danger btn-sm d-block"
              @click="$emit('remove', 'full', currentSelectedJobType)">- 当前整套</button>
          </div>
          <div class="col-4 mb-2 ps-1">
            <button type="button" class="btn btn-danger btn-sm d-block"
              @click="$emit('remove', 'clearCurrent', currentSelectedJobType)">清空当前</button>
          </div>
          <div class="col-4 mb-2 pe-0">
            <button type="button" class="btn btn-secondary btn-sm d-block"
              @click="$emit('add', 'gear', currentSelectedJobType)">+ 防具</button>
          </div>
          <div class="col-4 mb-2 ps-2 pe-1">
            <button type="button" class="btn btn-secondary btn-sm d-block"
              @click="$emit('add', 'accessories', currentSelectedJobType)">+ 首饰</button>
          </div>
          <div class="col-4 mb-2 ps-1">
            <button type="button" class="btn btn-danger btn-sm d-block"
              @click="$emit('remove', 'clearAll')">清空全部</button>
          </div>

          <div class="col-6 mb-2">
            <div class="input-group input-group-sm">
              <span class="input-group-text">头部</span>
              <input type="text" class="form-control text-center" readonly
                :value="gearCount[currentSelectedJobType] == null ? 0 : gearCount[currentSelectedJobType].head">
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('add', 'head', currentSelectedJobType)"><i class="bi bi-plus-lg"></i></button>
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('remove', 'head', currentSelectedJobType)"><i class="bi bi-dash-lg"></i></button>
            </div>
          </div>
          <div class="col-6 mb-2">
            <div class="input-group input-group-sm">
              <span class="input-group-text">身体</span>
              <input type="text" class="form-control text-center" readonly
                :value="gearCount[currentSelectedJobType] == null ? 0 : gearCount[currentSelectedJobType].body">
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('add', 'body', currentSelectedJobType)"><i class="bi bi-plus-lg"></i></button>
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('remove', 'body', currentSelectedJobType)"><i class="bi bi-dash-lg"></i></button>
            </div>
          </div>
          <div class="col-6 mb-2">
            <div class="input-group input-group-sm">
              <span class="input-group-text">手部</span>
              <input type="text" class="form-control text-center" readonly
                :value="gearCount[currentSelectedJobType] == null ? 0 : gearCount[currentSelectedJobType].hand">
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('add', 'hand', currentSelectedJobType)"><i class="bi bi-plus-lg"></i></button>
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('remove', 'hand', currentSelectedJobType)"><i class="bi bi-dash-lg"></i></button>
            </div>
          </div>
          <div class="col-6 mb-2">
            <div class="input-group input-group-sm">
              <span class="input-group-text">腿部</span>
              <input type="text" class="form-control text-center" readonly
                :value="gearCount[currentSelectedJobType] == null ? 0 : gearCount[currentSelectedJobType].leg">
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('add', 'leg', currentSelectedJobType)"><i class="bi bi-plus-lg"></i></button>
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('remove', 'leg', currentSelectedJobType)"><i class="bi bi-dash-lg"></i></button>
            </div>
          </div>
          <div class="col-6">
            <div class="input-group input-group-sm">
              <span class="input-group-text">脚部</span>
              <input type="text" class="form-control text-center" readonly
                :value="gearCount[currentSelectedJobType] == null ? 0 : gearCount[currentSelectedJobType].feet">
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('add', 'feet', currentSelectedJobType)"><i class="bi bi-plus-lg"></i></button>
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('remove', 'feet', currentSelectedJobType)"><i class="bi bi-dash-lg"></i></button>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6 mb-2">
            <div class="input-group input-group-sm">
              <span class="input-group-text">耳饰</span>
              <input type="text" class="form-control text-center" readonly
                :value="gearCount[currentSelectedJobType] == null ? 0 : gearCount[currentSelectedJobType].earring">
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('add', 'earring', currentSelectedJobType)"><i class="bi bi-plus-lg"></i></button>
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('remove', 'earring', currentSelectedJobType)"><i class="bi bi-dash-lg"></i></button>
            </div>
          </div>
          <div class="col-6 mb-2">
            <div class="input-group input-group-sm">
              <span class="input-group-text">项链</span>
              <input type="text" class="form-control text-center" readonly
                :value="gearCount[currentSelectedJobType] == null ? 0 : gearCount[currentSelectedJobType].necklace">
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('add', 'necklace', currentSelectedJobType)"><i class="bi bi-plus-lg"></i></button>
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('remove', 'necklace', currentSelectedJobType)"><i class="bi bi-dash-lg"></i></button>
            </div>
          </div>
          <div class="col-6">
            <div class="input-group input-group-sm">
              <span class="input-group-text">手镯</span>
              <input type="text" class="form-control text-center" readonly
                :value="gearCount[currentSelectedJobType] == null ? 0 : gearCount[currentSelectedJobType].bracelet">
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('add', 'bracelet', currentSelectedJobType)"><i class="bi bi-plus-lg"></i></button>
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('remove', 'bracelet', currentSelectedJobType)"><i class="bi bi-dash-lg"></i></button>
            </div>
          </div>
          <div class="col-6">
            <div class="input-group input-group-sm">
              <span class="input-group-text">戒指</span>
              <input type="text" class="form-control text-center" readonly
                :value="gearCount[currentSelectedJobType] == null ? 0 : gearCount[currentSelectedJobType].ring">
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('add', 'ring', currentSelectedJobType)"><i class="bi bi-plus-lg"></i></button>
              <button class="btn btn-outline-secondary" type="button"
                @click="$emit('remove', 'ring', currentSelectedJobType)"><i class="bi bi-dash-lg"></i></button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
//import { toRef, watch } from 'vue'

export default {
  name: "ItemSetGearSelection",
  props: ["type", "gearCount"],
  emits: ["add", "remove"],
  data() {
    return {
      currentSelectedJobType: 0,
    };
  },
  created() {
    if (this.type == '1') {
      this.currentSelectedJobType = 1;
    }

    if (this.type == '2') {
      this.currentSelectedJobType = 10;
    }
  }
  /*setup(props) {
    const currentType = toRef(props, 'type');

    //console.log(currentType);

    watch(currentType, () => {
      console.log(currentType);

      if (this.currentType == '1') {
        this.currentSelectedJobType = 1;
      }

      if (this.currentType == '2') {
        this.currentSelectedJobType = 10;
      }
    });
  },*/
}
</script>

<style scoped>
.d-block {
  display: block;
  width: 100%;
}
</style>