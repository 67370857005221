<template>
  <div class="card mt-2">
    <div class="card-body">
      <template v-if="type == 1">
        <div class="me-1 job-icon job-icon-pld" @click="selectJob(19)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 19"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-war" @click="selectJob(21)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 21"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-drk" @click="selectJob(32)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 32"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-gnb" @click="selectJob(37)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 37"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-whm" @click="selectJob(24)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 24"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-sch" @click="selectJob(28)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 28"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-ast" @click="selectJob(33)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 33"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-sge" @click="selectJob(40)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 40"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-mnk" @click="selectJob(20)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 20"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-drg" @click="selectJob(22)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 22"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-nin" @click="selectJob(30)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 30"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-sam" @click="selectJob(34)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 34"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-rpr" @click="selectJob(39)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 39"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-brd" @click="selectJob(23)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 23"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-mch" @click="selectJob(31)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 31"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-dnc" @click="selectJob(38)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 38"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-blm" @click="selectJob(25)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 25"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-smn" @click="selectJob(27)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 27"></ItemSetCheckedJob>
        </div>
        <div class="me-1 job-icon job-icon-rdm" @click="selectJob(35)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 35"></ItemSetCheckedJob>
        </div>
      </template>

      <template v-if="type == 2">
        <div class="me-1 job-icon job-icon-crp" @click="selectJob(8)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 8"></ItemSetCheckedJob>
        </div>

        <div class="me-1 job-icon job-icon-bsm" @click="selectJob(9)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 9"></ItemSetCheckedJob>
        </div>

        <div class="me-1 job-icon job-icon-arm" @click="selectJob(10)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 10"></ItemSetCheckedJob>
        </div>

        <div class="me-1 job-icon job-icon-gsm" @click="selectJob(11)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 11"></ItemSetCheckedJob>
        </div>

        <div class="me-1 job-icon job-icon-ltw" @click="selectJob(12)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 12"></ItemSetCheckedJob>
        </div>

        <div class="me-1 job-icon job-icon-wvr" @click="selectJob(13)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 13"></ItemSetCheckedJob>
        </div>

        <div class="me-1 job-icon job-icon-alc" @click="selectJob(14)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 14"></ItemSetCheckedJob>
        </div>

        <div class="me-1 job-icon job-icon-cul" @click="selectJob(15)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 15"></ItemSetCheckedJob>
        </div>

        <div class="me-1 job-icon job-icon-min" @click="selectJob(16)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 16"></ItemSetCheckedJob>
        </div>

        <div class="me-1 job-icon job-icon-btn" @click="selectJob(17)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 17"></ItemSetCheckedJob>
        </div>

        <div class="me-1 job-icon job-icon-fsh" @click="selectJob(18)">
          <ItemSetCheckedJob v-if="currentSelectedJob == 18"></ItemSetCheckedJob>
        </div>
      </template>

      <div class="row" v-if="type != 0">
        <template v-if="type == 2">
          <div class="col-6 mb-2 pe-1">
            <button type="button" class="btn btn-primary btn-sm d-block" @click="$emit('add', 101)">+ 全套生产工具</button>
          </div>
          <div class="col-6 mb-2 ps-1">
            <button type="button" class="btn btn-primary btn-sm d-block" @click="$emit('add', 102)">+ 全套采集工具</button>
          </div>
        </template>
        <div class="col-7 pe-1 text-center text-muted" v-if="currentSelectedJob == 0">
          <span class="align-middle">添加单职业请选择职业</span>
        </div>
        <div class="col-7 pe-1" v-else>
          <div class="input-group input-group-sm">
            <span class="input-group-text">{{ type == 1 ? '武器' : '工具' }}</span>
            <input type="text" class="form-control text-center" readonly
              :value="weaponCount[currentSelectedJob] == null ? 0 : weaponCount[currentSelectedJob]">
            <button class="btn btn-outline-secondary" type="button" @click="$emit('add', currentSelectedJob)"><i
                class="bi bi-plus-lg"></i></button>
            <button class="btn btn-outline-secondary" type="button" @click="$emit('remove', currentSelectedJob)"><i
                class="bi bi-dash-lg"></i></button>
          </div>
        </div>
        <div class="col-5 ps-1">
          <button type="button" class="btn btn-danger btn-sm d-block" @click="$emit('clear')">清空所有{{ type == 1 ? '武器' :
              '工具'
          }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemSetCheckedJob from './ItemSetCheckedJob.vue';

export default {
  name: "ItemSetWeaponSelection",
  components: {
    ItemSetCheckedJob,
  },
  props: ["type", "weaponCount"],
  emits: ["add", "remove", "clear"],
  data() {
    return {
      currentSelectedJob: 0,
    };
  },
  methods: {
    selectJob(id) {
      if (id == this.currentSelectedJob) {
        this.currentSelectedJob = 0;
      } else {
        this.currentSelectedJob = id;
      }
    },
  },
}
</script>

<style scoped>
.d-block {
  display: block;
  width: 100%;
}

.job-icon {
  height: 32px;
  width: 32px;
  display: inline-block;
  margin-bottom: 5px;
  background-size: 100% 100%;
  cursor: pointer;
}

.job-icon img {
  height: 32px;
  width: 32px;
}

.job-icon-pld {
  background-image: url('../../assets/job/pld.png');
}

.job-icon-war {
  background-image: url('../../assets/job/war.png');
}

.job-icon-drk {
  background-image: url('../../assets/job/drk.png');
}

.job-icon-gnb {
  background-image: url('../../assets/job/gnb.png');
}

.job-icon-whm {
  background-image: url('../../assets/job/whm.png');
}

.job-icon-sch {
  background-image: url('../../assets/job/sch.png');
}

.job-icon-ast {
  background-image: url('../../assets/job/ast.png');
}

.job-icon-sge {
  background-image: url('../../assets/job/sge.png');
}

.job-icon-mnk {
  background-image: url('../../assets/job/mnk.png');
}

.job-icon-drg {
  background-image: url('../../assets/job/drg.png');
}

.job-icon-nin {
  background-image: url('../../assets/job/nin.png');
}

.job-icon-sam {
  background-image: url('../../assets/job/sam.png');
}

.job-icon-rpr {
  background-image: url('../../assets/job/rpr.png');
}

.job-icon-brd {
  background-image: url('../../assets/job/brd.png');
}

.job-icon-mch {
  background-image: url('../../assets/job/mch.png');
}

.job-icon-dnc {
  background-image: url('../../assets/job/dnc.png');
}

.job-icon-blm {
  background-image: url('../../assets/job/blm.png');
}

.job-icon-smn {
  background-image: url('../../assets/job/smn.png');
}

.job-icon-rdm {
  background-image: url('../../assets/job/rdm.png');
}

.job-icon-crp {
  background-image: url('../../assets/job/crp.png');
}

.job-icon-bsm {
  background-image: url('../../assets/job/bsm.png');
}

.job-icon-arm {
  background-image: url('../../assets/job/arm.png');
}

.job-icon-gsm {
  background-image: url('../../assets/job/gsm.png');
}

.job-icon-ltw {
  background-image: url('../../assets/job/ltw.png');
}

.job-icon-wvr {
  background-image: url('../../assets/job/wvr.png');
}

.job-icon-alc {
  background-image: url('../../assets/job/alc.png');
}

.job-icon-cul {
  background-image: url('../../assets/job/cul.png');
}

.job-icon-min {
  background-image: url('../../assets/job/min.png');
}

.job-icon-btn {
  background-image: url('../../assets/job/btn.png');
}

.job-icon-fsh {
  background-image: url('../../assets/job/fsh.png');
}
</style>